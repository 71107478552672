import React, { useState, useEffect } from "react";
import "./styles/styles.css";
import ServiceCard from "./components/service-card/index.tsx";
import { services } from "./content/main.ts";
import Auth from "./components/auth/index.tsx";

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => { 
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, [])
  
  return (
    <div className="App">
      <header className="header">
        <h1>Mid Tools</h1>
        <Auth user={ user } setUser={ setUser } />
      </header>
      {user ? (
        <main className="catalog">
          {services.map((service, i) => (
              <ServiceCard
                key={i+1}
                title={service.title}
                description={service.description}
                buttons={service.buttons}
              />
            )
          )}
        </main>
      ) : (
        <main className="catalog hidden">
          <p>Please log in to view the services.</p>
        </main>
      )}
      <footer className="footer">
        <p>&copy; 2024 Your Company Name. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;