export const services = [
    {
      title: "Analytics",
      description: "Сбор данных и формирование отчетов по ключевым метрикам.",
      buttons: [{label: "Go to", href: "https://analytics.mid.tools/"}]
    },
    {
      title: "Alanbase sync",
      description: "Синхронизация данных из alanbase и актуализация курсов валют.",
      buttons: [{label: "Module docs", href: "https://conversions.mid.tools/docs/scripts/"},{label: "Api docs", href: "https://conversions.mid.tools/docs/api/"}]
    },
    {
      title: "Sms service",
      description: "Сервис для отправки смс в обход основной апи.",
      buttons: [{label: "Api docs", href: "https://api.mid.tools/docs/#api-Otp"}]
    },
    {
      title: "Segmentation",
      description: "Автоматизация управления и наполнения сегментами пользователей.",
      buttons: [{label: "Api docs", href: "https://api.mid.tools/docs/#api-Segmentation"}]
    },
]