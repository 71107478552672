import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyANmL14W8SiXLS_e_VEp9gb-pyeTeJAukI",
    authDomain: "analytics-auth-e9186.firebaseapp.com",
    projectId: "analytics-auth-e9186",
    storageBucket: "analytics-auth-e9186.firebasestorage.app",
    messagingSenderId: "66563874861",
    appId: "1:66563874861:web:8416ff3bcd35bc463c18a4",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const handleAuthAction = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    alert(error.message);
  }
};

const handleLogout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Logout failed", error);
  }
};

export {
  handleAuthAction,
  handleLogout
};