import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { handleAuthAction, handleLogout } from "../../models/auth.ts";
import "./styles/index.css";

type AuthProps = {
    user: any;
    setUser: Function
};

const Auth: React.FC<AuthProps> = ({ user, setUser }) => { 
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(['session_key']);

    const onLogin = async () => { 
        const user = await handleAuthAction(email, password);
        localStorage.setItem("user", JSON.stringify(user));
        let d = new Date();
        d.setTime(d.getTime() + (24*60*60*1000));
        setCookie("session_key", btoa(JSON.stringify(user)), {domain: ".mid.tools", path: "/", expires: d});
        setUser(user);
    }

    const onLogout = async () => { 
        setUser(null);
        localStorage.removeItem("user");
        removeCookie("session_key", {domain: ".mid.tools"})
        await handleLogout();
    }

    return user ? (
            <div className="auth-form">
                <button onClick={onLogout} className="auth-form__btn">Logout</button>
                <span>Welcome, {user.email}!</span>
            </div>
        ) : (
            <div className="auth-form">
                <h2>Login</h2>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    onKeyDown={(e) => (e.key === "Enter" ? onLogin() : "")}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    onKeyDown={(e) => (e.key === "Enter" ? onLogin() : "")}
                />
                <button
                    className="auth-form__btn"
                    onClick={onLogin}
                >
                    Login
                </button>
            </div>
        )
}

export default Auth;