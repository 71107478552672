import React from "react";
import "./styles/index.css";

type ServiceCardProps = {
    title: string;
    description: string;
    buttons: {
      label: string;
      href: string;
    }[]
};

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, buttons }) => (
    <div className="service-card">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="service-card__btn-group" >
        {buttons.map((button, i) => (
          <a key={ i } href={button.href} className="service-card__btn" target="_blank" rel="noreferrer">{button.label}</a>
          )
        )}
      </div>
    </div>
);

export default ServiceCard;